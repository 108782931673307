@import '../../../style/mixins';

.BlockPersonGallery {
  position: relative;
  margin: $block-margin-text;
  padding-bottom: $block-padding-bottom;
  overflow-x: hidden;

  @include tablet {
    margin: $block-margin-text-tablet;
  }

  @include mobile {
    margin: $block-margin-text-mobile;
  }

  &__title {
    width: 100%;
    padding: 2 * $lh-normal $page-padding-horizontal $lh-normal;
    text-align: center;

    h2 {
      @include font-normal;
    }
  }

  &__grid {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + #{$block-persongallery-item-margin-right});

    &__item {
      flex: 0 1 33.333333%;
      margin-bottom: $block-persongallery-item-margin-bottom;
      border-right: $block-persongallery-item-margin-right solid transparent;

      @include mobile {
        flex-basis: 50%;
      }

      @include tiny {
        flex-basis: 100%;
      }

      &__figure {
        position: relative;
        width: 100%;
        margin: 0;
      }

      &__ratio {
        height: 0;
        overflow: hidden;
      }

      &__img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__caption {
        @include font-small;
        @include text-styles-small;
        padding-top: 5px;
        text-align: center;
      }
    }
  }
}
