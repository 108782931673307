@import '../constants';

@mixin font-family($family) {
  @if $family == serif {
    font-family: 'Quadrant Text', Georgia, Times, 'Times New Roman', serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == sans-serif {
    font-family: Helevetica Neue, Helvetica, Arial, sans-serif;
  } @else if $family == monospace {
    font-family: 'Quadrant Text Mono', 'Monaco', 'Courier New', monospace;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else {
    @warn 'Unknown font-family specified, please check for spelling mistakes';
  }
}

@mixin font-large-mono {
  @include font-family('monospace');
  font-size: $fs-large;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-large;

  @include mobile {
    font-size: $fs-large-mobile;
    line-height: $lh-large-mobile;
  }
}

@mixin font-large {
  @include font-family('serif');
  font-size: $fs-large;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-large;

  @include mobile {
    font-size: $fs-large-mobile;
    line-height: $lh-large-mobile;
  }
}

@mixin font-normal {
  @include font-family('serif');
  font-size: $fs-normal;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-normal;

  @include mobile {
    font-size: $fs-normal-mobile;
    line-height: $lh-normal-mobile;
  }

  p {
    padding-bottom: $lh-normal;

    @include mobile {
      padding-bottom: $lh-normal-mobile;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@mixin font-normal-mono {
  @include font-family('monospace');
  font-size: $fs-normal;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-normal;

  @include mobile {
    font-size: $fs-normal-mobile;
    line-height: $lh-normal-mobile;
  }
}

@mixin font-small {
  @include font-family('serif');
  font-size: $fs-small;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-small;

  p {
    @include font-family('serif');
    font-size: $fs-small;
    font-style: normal;
    font-weight: normal;
    line-height: $lh-small;
    padding-bottom: $lh-small;
  }

  @include mobile {
    font-size: $fs-small-mobile;
    line-height: $lh-small-mobile;

    p {
      padding-bottom: $lh-small-mobile;
    }
  }
}

@mixin font-small-mono {
  @include font-family('monospace');
  font-size: $fs-small;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-small;
}

@mixin text-styles {
  a {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color: inherit;
    text-decoration: none;

    @include mobile {
      word-break: break-word;
    }

    &:before {
      position: absolute;
      top: 1px;
      left: 0;
      content: '→';
      white-space: nowrap;
      padding-right: 3px;
    }

    .no-touchevents &:hover {
      color: $color-grey-dark;
    }
  }

  em,
  i {
    font-style: italic;
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: inherit;
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 $lh-normal;
    list-style: none;

    li {
      padding-left: 20px;
      list-style-type: none;

      &::before {
        margin-right: 10px;
        margin-left: -20px;
        content: '–';
      }
    }
  }
}

@mixin text-styles-mono {
  a {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color: inherit;
    text-decoration: none;

    @include mobile {
      word-break: break-word;
    }

    &:before {
      position: absolute;
      top: -1px;
      left: 0;
      content: '→';
      white-space: nowrap;
      padding-right: 3px;
      @include font-family('serif');
    }

    .no-touchevents &:hover {
      color: $color-grey-dark;
    }
  }

  em,
  i {
    font-style: italic;
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: inherit;
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 $lh-normal;
    list-style: none;

    li {
      padding-left: 20px;
      list-style-type: none;

      &::before {
        margin-right: 10px;
        margin-left: -20px;
        content: '–';
      }
    }
  }
}

@mixin text-styles-small {
  a {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color: inherit;
    text-decoration: none;

    @include mobile {
      word-break: break-word;
    }

    &:before {
      position: absolute;
      top: -1px;
      left: 0;
      content: '→';
      white-space: nowrap;
      padding-right: 3px;
    }

    .no-touchevents &:hover {
      color: $color-grey-dark;
    }
  }

  em,
  i {
    font-style: italic;
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: inherit;
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 $lh-normal;
    list-style: none;

    li {
      padding-left: 20px;
      list-style-type: none;

      &::before {
        margin-right: 10px;
        margin-left: -20px;
        content: '–';
      }
    }
  }
}

@mixin text-selection {
  ::-moz-selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }

  ::selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }
}

@mixin text-selection-inverted {
  ::-moz-selection {
    color: $color-black;
    background: rgba(255, 255, 255, 0.99);
  }

  ::selection {
    color: $color-black;
    background: rgba(255, 255, 255, 0.99);
  }
}
