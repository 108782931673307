@import '../../../style/mixins';

.BlockSlideshow {
  position: relative;
  width: 100%;
  padding-bottom: $block-padding-bottom;
  user-select: none;

  &__inner {
    position: relative;
    width: 100%;
    min-height: $block-slideshow-height-medium;
    cursor: grab;
  }

  &--size-large &__inner {
    min-height: $block-slideshow-height-large;
  }

  &--size-small &__inner {
    min-height: $block-slideshow-height-small;
  }

  &__img {
    margin: 0 0 0 6px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__caption {
    @include font-small;
    @include text-styles-small;
    padding: 5px ($stream-meta-padding-horizontal - $page-padding-horizontal) 0;
  }

  .swiper-container {
    overflow-y: visible;
  }

  .swiper-slide {
    flex: 0 0 auto;
    height: 100%;
    width: auto;
  }

  // show bullets on mobile
  .swiper-pagination-bullets {
    display: none;
  }
}
