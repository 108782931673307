@import '../../../style/mixins';

.Category {
  position: relative;
  background: $color-black;

  &__inner {
    margin: 0 $page-padding-horizontal;
    background: $color-grey-light;
  }

  &__text {
    @include font-small;
    @include text-styles-small;
    margin: 0 10%;
    padding: 2 * $page-padding-vertical 0 4 * $page-padding-vertical;
  }
}
