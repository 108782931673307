@font-face {
  font-family: 'Quadrant Text';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/QuadrantText-Regular.woff2') format('woff2'),
    url('fonts/QuadrantText-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Quadrant Text';
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: url('fonts/QuadrantText-RegularItalic.woff2') format('woff2'),
    url('fonts/QuadrantText-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Quadrant Text Mono';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/QuadrantTextMono-Regular.woff2') format('woff2'),
    url('fonts/QuadrantTextMono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Quadrant Text Mono';
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: url('fonts/QuadrantTextMono-RegularItalic.woff2') format('woff2'),
    url('fonts/QuadrantTextMono-RegularItalic.woff') format('woff');
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Quadrant Text';
  font-size: $fs-normal;
  font-style: normal;
  font-weight: normal;
  line-height: $lh-normal;
  margin: 0;
  padding: 0;
}
