@import '../../../style/mixins';

.AgendaItem {
  @include font-normal-mono;
  position: relative;
  width: 100%;

  &--dark &__inner {
    background: $color-grey;
  }

  &__inner {
    min-height: $stream-min-height;
    margin: 0 $page-padding-horizontal;
    background: $color-grey-light;

    @include tablet {
      min-height: auto;
    }

    @include mobile {
      min-height: auto;
    }
  }

  &__left {
    position: absolute;
    top: $stream-meta-padding-vertical;
    left: $stream-meta-padding-horizontal;

    @include tablet {
      position: relative;
      top: auto;
      left: $stream-padding-horizontal;
      padding-bottom: $stream-padding-vertical;
    }

    @include mobile {
      position: relative;
      top: auto;
      left: $stream-padding-horizontal;
      padding-bottom: $stream-padding-vertical;
    }
  }

  &__center {
    position: relative;
    padding-top: $stream-title-padding-top;
    padding-bottom: 2 * $lh-normal;
    text-align: center;

    @include mobile {
      padding-top: $stream-title-padding-top-mobile;
    }
  }

  &__date,
  &__time,
  &__location {
    display: block;
    white-space: pre-wrap;
  }

  &__headline,
  &__subtitle {
    @include block-margin-headline;

    h1,
    h2 {
      @include font-large;
    }

    a {
      color: $color-black;
      text-decoration: none;

      @include mobile {
        word-break: break-word;
      }

      .no-touchevents &:hover {
        color: $color-grey-dark;
      }
    }
  }

  &__lead {
    @include block-margin-lead;
    @include text-styles-mono;
    padding-top: $stream-lead-padding-top;

    &,
    p {
      @include font-normal-mono;
    }
  }
}
