@import '../../../style/mixins';

.BlockImage {
  @include block-margin-media;
  position: relative;
  padding-bottom: $block-padding-bottom;

  &__figure {
    position: relative;
    width: 100%;
    margin: 0;
  }

  &__ratio {
    height: 0;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;

    @include mobile {
      object-fit: cover;
    }
  }

  &__caption {
    @include font-small;
    @include text-styles-small;
    padding-top: 5px;

    p {
      text-align: center;
    }
  }
}
